
export default {
    data() {
      return {
        appsideguid: 'f2cb03a2b3e84149df939753f9825054',
        platformGuid: '1e2409b7ef0b412c2bb84e38f8fe50ba',
        validateCode: '',
        userStatusType: 1,
      };
    },
    methods: {
      ylCloudsubmitForm(serviceCode,productRoute) {
        var self = this;
        var appsideguid = this.appsideguid;
        var promise = {
          username: self.$route.query.username,
          password: self.$route.query.password,
          serviceCode: serviceCode,
          appSideGuid: appsideguid,
          targetMenuPath:productRoute
        };
        self.api.postJson('/ms-user-unified-authentication-service/user/login', promise).then((res) => {
          if (res.data.code == '00000') {
            let url = res.data.data[0].url;
            //window.location = url;
            window.open(url)
          } else {
            self.$message.error(res.data.msg);
          }
        });
      },
      sellApprovalSubmitForm(item) {
        var self = this;
        var url;
        var tenantGuid = this.currentTenantGuid;
        var frontEnv = window.env;//前端环境
        var systemEnv = localStorage.systemEnv;//后台环境
        if (frontEnv === 'pro'&&systemEnv=='pro') {
          //生产1
          url = `https://scm.csyaoly.com/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&productGuid=${item.productGuid}&logoTitle=${self.$route.query.logoTitle}&token=${localStorage.token}`;
        }else if (frontEnv === 'pro'&&systemEnv=='pre') {
          //生产2
          url = `https://scm.cs4pl.com/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&productGuid=${item.productGuid}&logoTitle=${self.$route.query.logoTitle}&token=${localStorage.token}`;
        } else if (frontEnv === 'sit'&&systemEnv=='sit') {
          //测试1
          url = `https://scm-test.cs4pl.com/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&productGuid=${item.productGuid}&logoTitle=${self.$route.query.logoTitle}&token=${localStorage.token}`;
        } else if (frontEnv === 'sit'&&systemEnv=='uat') {
          //测试2
          url = `https://scm-pre-test.cs4pl.com/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&productGuid=${item.productGuid}&logoTitle=${self.$route.query.logoTitle}&token=${localStorage.token}`;
        }else{
          //开发
          url = `http://localhost:8084/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&productGuid=${item.productGuid}&logoTitle=${self.$route.query.logoTitle}&token=${localStorage.token}`
        }
  
        //window.location = url;
        window.open(url)
      },
      //消息待办跳转
      sellApprovalSubmitFormMsg(item, path) {
        var self = this;
        var url;
        var tenantGuid = this.currentTenantGuid;
        var productGuid = item.productGuid
        var tenantProductGuid = item.tenantProductGuid
        var frontEnv = window.env;//前端环境
        var systemEnv = localStorage.systemEnv;//后台环境
        if (frontEnv === 'pro'&&systemEnv=='pro') {
          //生产1
          url = `https://scm.csyaoly.com/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&path=${encodeURIComponent(path)}&logoTitle=${self.$route.query.logoTitle}&productGuid=${productGuid}&tenantProductGuid=${tenantProductGuid}&token=${localStorage.token}`;
        }else if (frontEnv === 'pro'&&systemEnv=='pre') {
          //生产2
          url = `https://scm.cs4pl.com/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&path=${encodeURIComponent(path)}&logoTitle=${self.$route.query.logoTitle}&productGuid=${productGuid}&tenantProductGuid=${tenantProductGuid}&token=${localStorage.token}`
        } else if (frontEnv === 'sit'&&systemEnv=='sit') {
          //测试1
          url = `https://scm-test.cs4pl.com/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&path=${encodeURIComponent(path)}&logoTitle=${self.$route.query.logoTitle}&productGuid=${productGuid}&tenantProductGuid=${tenantProductGuid}&token=${localStorage.token}`
        } else if (frontEnv === 'sit'&&systemEnv=='uat') {
          //测试2
          url = `https://scm-pre-test.cs4pl.com/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&path=${encodeURIComponent(path)}&logoTitle=${self.$route.query.logoTitle}&productGuid=${productGuid}&tenantProductGuid=${tenantProductGuid}&token=${localStorage.token}`
        } else {
          //开发
          url = `http://localhost:8084/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&path=${encodeURIComponent(path)}&logoTitle=${self.$route.query.logoTitle}&productGuid=${productGuid}&tenantProductGuid=${tenantProductGuid}&token=${localStorage.token}`
        }
        //window.location = url;
        window.open(url)
      },
      operationSubmitForm(item){
        var self = this;
        var url;
        var tenantGuid = this.currentTenantGuid;
        if (env === 'pro') {
          //生产
          url = `https://scm-operation.csyaoly.com/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&productGuid=${item.productGuid}&tenantProductGuid=${item.tenantProductGuid}&logoTitle=${self.$route.query.logoTitle}&token=${localStorage.token}`;
        } else if (env === 'sit') {
          //测试
          url = `http://43.254.1.27:8903/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&productGuid=${item.productGuid}&tenantProductGuid=${item.tenantProductGuid}&logoTitle=${self.$route.query.logoTitle}&token=${localStorage.token}`;
        }else if (env === 'pre') {
          //测试
          url = `http://43.254.3.69:3021/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&productGuid=${item.productGuid}&tenantProductGuid=${item.tenantProductGuid}&logoTitle=${self.$route.query.logoTitle}&token=${localStorage.token}`;
        } else {
          //开发
          url = `http://localhost:8086/LoginAccess?tenantGuid=${tenantGuid}&username=${self.$route.query.username}&password=${self.$route.query.password}&productGuid=${item.productGuid}&logoTitle=${self.$route.query.logoTitle}&token=${localStorage.token}`
        }
        window.open(url)
      },
      ylCloundJump(item) {
        var self = this;
        var promise = {
          name: item.productName,
          pageIndex: 1,
          pageSize: -1,
        };
        self.api.postJson('/ms-user-unified-authentication-service/services/get', promise).then((res) => {
          self.ylCloudsubmitForm(res.data.data[0].code,item.productRoute);
        });
      },
      sellApprovalJump(item) {
        this.sellApprovalSubmitForm(item);
      },
      operationJump(item){
        this.operationSubmitForm(item);
      },
      platformJump(item) {
        if(item.productRoute.indexOf('yaoly')>-1){
          this.ylCloundJump(item);
        }else{
          this.sellApprovalJump(item);
        }
      },
      messageJump(item, url) {
        let userInfo = JSON.parse(localStorage.userInfo);
        let req = {
          "tenantGuid": this.$route.query.currentTenantGuid,
          "appSideGuid": 'f2cb03a2b3e84149df939753f9825054',
          "userGuid": userInfo.userGuid,
          "menuPath": item.jumpUrl
        }
       this.api
        .postJson(`/${serverConfig.msCommonAdminPlatformAuthService}/product/data/get-product-guid`,req)
        .then((res) => {
          if (res.data.code == serverConfig.REQUIRE_SUCCESS_CODE) {
            console.log(res.data)
            let tenantProductGuid = res.data.data;
            item.tenantProductGuid=tenantProductGuid
            this.sellApprovalSubmitFormMsg(item, url);
          } else {
            this.$message.error(res.data.msg);
          }
        });
        
      },
  
    }
  };
  
  
  